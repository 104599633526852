/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "da",
      "3166-1": "DK",
      "IETF": "da-DK",
    },
    "CURRENCY": "DKK",
    "CURRENCY_SHORT": "kr",
    "POINTS": "point",
    "TYPEKIT_ID": "thm8nca",
  },
  "COOKIE_CONSENT": {
    "NOTICE": "For at forbedre og tilpasse din oplevelse af vores digitale tjenester bruger vi cookies. Ved at fortsætte med at bruge webstedet accepterer du brugen af ​​cookies.",
    "ALL": "Tillad alle",
    "NECESSARY": "Kun nødvendige",
    "SELECTED": "Tillad valg",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Nødvendig",
    "STATISTICAL": "Statistisk",
    "MARKETING": "Markedsføring",
  },
  "IE_NOTICE": "Du bruger en browser, som vi ikke understøtter. Webstedet har begrænset funktionalitet, hvis du bruger denne browser.",
  "COMMON": {
    "SAVE_AS_PDF": "Gem som PDF",
    "PRINT": "Print",
    "CLOSE": "Luk",
    "CANCEL": "Annuller",
    "SEND": "Sende",
    "REMOVE": "Fjern",
    "APPROVE": "Godkende",
    "READ_MORE": "Læs mere",
    "FROM": "Fra",
    "TO": "Til",
    "SEE_ALL": "Se alle",
    "EDIT": "Redigere",
    "ADD": "Tilføje",
    "GO_BACK": "Gå tilbage",
    "REGISTER": "Tilmeld",
    "I_ACCEPT_THE": "Jeg accepterer",
  },
  "LOGO": {
    "ALT": "Logo",
    "TITLE": "Gå til startsiden",
  },
  "HOMEVIEW": {
    "HERO_ALT": "Awardit",
    "POPULAR_BRANDS": "Populære mærker",
    "POPULAR_CATEGORIES": "Populære kategorier",
    "POPULAR_PRODUCTS": "Populære produkter",
    "SEE_MORE": "Se mere",
    "BECOME_CUSTOMER": "Bliv kunde",
  },
  "ERRORVIEW": {
    "TITLE": "Der opstod et fejl. Prøv igen.",
  },
  "TERMSVIEW": {
    "FALLBACK_TITLE": "Vilkår",
    "ERROR_FETCHING": "Indholdet på siden kunne ikke indlæses. Prøv igen.",
  },
  "LOGIN": {
    "TITLE": "Log ind",
    "USERNAME": "Brugernavn",
    "PASSWORD": "Adgangskode",
    "SUBMIT": "Log ind",
    "FORGOT_PASSWORD": "Har du glemt din adgangskode?",
    "RESET_PASSWORD": "Tryk her",
    "CREATE_ACCOUNT_HEADING": "Har du ikke en konto?",
    "CREATE_ACCOUNT": "Opret ny konto",
  },
  "RESET_PASSWORD": {
    "EMAIL": "Email adresse",
    "SUBMIT": "Send email",
  },
  "NEW_PASSWORD": {
    "TITLE": "Indtast ny adgangskode",
    "PASSWORD": "Ny adgangskode",
    "PASSWORD_REPEAT": "Gentag ny adgangskode",
    "SUBMIT": "Gem ny adgangskode",
  },
  "REGISTER": {
    "FIRSTNAME": "Fornavn",
    "LASTNAME": "Efternavn",
    "EMAIL": "Email",
    "PHONE": "Telefon",
    "COMPANY": "Firma",
    "ORG_NUMBER": "Organisationsnummer",
    "TOTAL_MEMBERS": "Antal medarbejdere",
    "STREET_ADDRESS": "Gadeadresse",
    "ZIPCODE": "Postnummer",
    "CITY": "By",
    "OTHER": "Andet",
    "PNR": "CPR-nummer",
    "GENDER": "Køn",
    "USER_ID": "Bruger-ID",
    "SHOP": "Butik",
    "DISTRICT": "Distrikt",
    "COUNTRY": "Land",
    "DATE_OF_BIRTH": "Fødselsdato",
    "PASSWORD": "Adgangskode",
    "REPEAT_PASSWORD": "Gentag adgangskode",
    "TERMS": "Jeg accepterer <a href='%link'>vilkår og betingelser</a>",
    "EXISITING_ACCOUNT": "Har du allerede en konto?",
  },
  "AFFILIATE": {
    "CTA": {
      "ACTIVATE": "Aktiver",
      "ACTIVATED": "Aktiveret",
      "PROCESSING": "Venter",
      "INACTIVATED": "Inaktiveret",
    },
  },
  "EARN": {
    "SEARCH": "Søg efter tilbud",
    "CLEAR_SEARCH": "Ryd søgefelt",
    "SHOW_LESS": "Vis mindre",
    "SHOW_MORE": "Læs mere",
    "START": "Start",
  },
  "BRAND": {
    "SEARCH": "Søg på mærker",
    "CLEAR_SEARCH": "Ryd søgefelt",
    "TOTAL_COUNT": "Viser %total produkt(er)",
    "EMPTY": "Der er ingen produkter, at vise lige nu",
  },
  "ACCOUNT": {
    "LOGIN": "Log ind",
    "LOGOUT": "Log ud",
    "MY_ACCOUNT": "Min konto",
    "USER": "Brugere",
    "MEMBERSHIP": "Medlemskab",
    "MEMBER_NUMBER": "Medlemsnr",
    "SHIPPING_ADDRESS": "Leveringsadresse",
    "ELAPSED_POINTS": "Udgående %pointsLabel",
    "ELAPSING_POINTS_INFO": "Du har %points %pointsLabel, der udløber den %date (%dateRelative)",
    "EDIT_INFO": "Ændre mine oplysninger",
    "NO_PHONE_NUMBER": "Intet telefonnummer",
    "WISHLIST": "Ønskeliste",
    "EMAIL": "Email",
    "BALANCE": "Saldo",
    "EXPIRES": "Udløber",
    "DATE": "Dato",
    "SEE_ALL": "Se alle",
    "EDIT": "Ændre",
    "OPEN": "Åben",
    "YOUR_POINTS": "Dine %pointsLabel",
    "NEWSLETTER": "Jeg vil have e -mails om nyheder og tilbud",
    "SAVING": "Gemmer...",
    "SAVE_AND_CLOSE": "Gem og luk",
    "CURRENT_PASSWORD": "Eksisterende adgangskode",
    "NEW_PASSWORD": "Ny adgangskode",
    "CONFIRM_NEW_PASSWORD": "Bekræft ny adgangskode",
    "GENDER": {
      "MALE": "Mand",
      "FEMALE": "Kvinde",
      "UNSPECIFIED": "Uspecificeret",
    },
    "MEMBER_GROUP": {
      "USERS": "Brugere",
      "OWNER": "Ejer",
      "CAN_LOGIN": "Kan logge ind",
      "DESCRIPTION": "Her kan du tilføje, administrere og fjerne brugere.",
      "EDIT_MEMBER": "Rediger medlem",
      "ADD_MEMBER": "Tilføj medlem",
      "SAVE_CHANGES": "Gem ændringer",
      "REMOVE_MEMBER": "Fjern medlem?",
      "REMOVE_MEMBER_CONFIRM": "Er du sikker på, at du vil fjerne %member?",
      "FULL_NAME": "Fulde navn",
      "MEMBER_ID": "Medlems-ID",
      "SIGN_INS": "Logins",
      "POINTS": "%pointsLabel",
      "NO_MEMBERS": "Gruppen har ingen medlemmer",
      "TRANSFER_POINTS": {
        "FORMHEADER": "Fordeling af %pointsLabel",
        "COMMENT": "Kommentar",
        "POINTS_AVAILABLE": "%pointsLabel til rådighed",
        "POINTS_HANDED_OUT": "Tildelt %pointsLabel",
        "POINTS_LEFT": "%pointsLabel tilbage",
        "CHOOSE_ALL": "Vælg alle",
        "DEFAULT_MESSAGE": "Overførsel",
        "HISTORY": {
          "HEADER": "Tidligere udbytter",
          "DATE": "Dato",
          "RECIPIENTS": "Modtager",
          "DISTRIBUTED": "Distribueret",
        },
      },
      "TRANSACTIONS": {
        "DISPLAY": "Vis transaktioner",
        "HEADER": "Transaktioner og balance",
        "REGISTRATION_DATE": "Dato for registrering:",
        "ACTIVATION_DATE": "Aktiveringsdato:",
        "CURRENT_BALANCE": "Nuværende balance:",
        "EXPIRING_POINTS": "Antal point, der udløber næste måned (%date):",
        "ACCUMULATED_BALANCE": "Akkumuleret saldo:",
        "NO_TRANSACTIONS": "Der er ingen tilgængelige transaktioner for dette medlem.",
      },
    },
  },
  "RECRUIT_A_FRIEND": {
    "LABEL_NAME": "Navn",
    "LABEL_EMAIL": "Email",
    "LABEL_MESSAGE": "Besked",
  },
  "TINK": {
    "ACTIVE": "Aktiv",
    "INACTIVE": "Inaktiv",
    "REMOVE_PROVIDER": "Slet",
    "RENEW_PROVIDER": "Forny",
    "REMAINING_DAYS": {
      "SINGULAR": "1 dag tilbage",
      "PLURAL": "%days dage tilbage",
    },
    "REMOVE_CONFIRM": "Er du sikker på, at du vil fjerne tilknytningen til \"%name\"?",
    "CONFIRM": {
      "TITLE": "Fjern tilknytning",
      "OK": "OK",
      "CANCEL": "Annuller",
    },
    "MODAL": {
      "TITLE": "Forny din godkendelse",
      "SUBTITLE_PART1": "For at fortsætte med at modtage %pointsLabel for dine køb skal du forny autorisationen til håndtering af dine transaktioner.",
      "SUBTITLE_PART2": "Forny din transaktions godkendelse her, eller gør det via",
      "YOUR_ACCOUNT": "din konto",
    },
  },
  "POINTS": {
    "HEADER": "Overfør %pointsLabel",
    "MY_POINTS": "Mine %pointsLabel",
    "INTRO": "Her kan du foretage overførsler mellem dine konti",
    "TRANSFERING": "Overfør...",
    "TRANSFER": "Før over %pointsLabel",
    "AMOUNT_OF_POINTS": "Hvor mange %pointsLabel skal overføres?",
    "SELECT_ACCOUNT": "Vælg konto",
    "FROM_ACCOUNT": "Fra konto",
    "TO_ACCOUNT": "Til konto",
    "AMOUNT": "Saldo",
  },
  "QUIZ_ITEM": {
    "NUM_QUESTIONS": "%questions spørgsmål",
    "POINTS": "%points %pointsLabel",
    "STATUS": {
      "CLOSED": "Lukket",
      "ANSWERED": "Besvaret",
      "UNANSWERED": "Ej besvaret",
    },
    "SHOW_ANSWER": "Vis svar",
  },
  "QUIZ": {
    "HEADING": "Spørg %step af %of",
    "INPUT_LABEL": "Indtast dit svar",
    "LIST_ALTERNATIV_PLACEHOLDER": "Vælg indstillinger",
    "IMAGE_INPUT_LABEL": "Upload billede",
    "IMAGE_INPUT_BUTTON": "Vælg fil",
    "NEXT": "Fortsæt",
    "SUBMIT": "Gem",
    "ALREADY_ANSWERED": "Du har allerede besvaret denne undersøgelse. Nedenfor finder du dine svar.",
    "CLOSED": "Tidspunktet for denne undersøgelse er udløbet. Du kan ikke længere svare på den.",
    "EMPTY": "Der er intet at vise",
    "SUCCES": {
      "HEADING": "Tak fordi du deltog!",
      "INFO": "Som tak får du %point %pointsLabel til brug i vores butik.",
      "BONUS": "%point %pointsLabel er blevet tilføjet til din saldo, opdateringen af ​​saldoen vil blive vist næste gang du logger ind.",
      "BACK": "Til startsiden",
      "ALREADY_ANSWERED": "Du har allerede svaret på denne undersøgelse.",
      "COULD_NOT_SAVE": "Kunne ikke gemme dine svar. Sørg for, at alle spørgsmål er udfyldt, eller kontakt support.",
      "QUESTIONNAIRE_CLOSED": "Tidspunktet for denne undersøgelse er udløbet. Du kan ikke længere svare på den.",
    },
    "HISTORY_BACK": "Tag testen igen",
  },
  "QUESTIONNAIRE": {
    "EMPTY_LIST": "Der er i øjeblikket ingen undersøgelser inden for denne kategori",
  },
  "FILTER": {
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT": "Vis resultater",
      "SHOW_RESULT_TOTAL_MULTIPLE": "Vis %total resultater",
      "SHOW_RESULT_TOTAL_SINGLE": "Vis %total resultat",
      "CLOSE_MENU": "Luk filtermenu",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Ryd alle filtre",
      "NO_ACTIVE_FILTERS": "Du har ingen aktive filtre",
      "PRICE_RANGE": "Pris (%range)",
      "WITHIN_BALANCE": "Produkter inden for pointbalance (%range)",
      "TOTAL_COUNT": "Viser %count resultater",
      "COUNT": "Aktivt filter",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filtrer efter pris",
      "FILTER_BY_POINTS": "Filtrer efter %pointsLabel",
      "RESET_PRICE": "Ryd",
      "PRICE_FROM": "Fra",
      "PRICE_TO": "Til",
      "WITHIN_BALANCE": "Produkter inden for pointbalance",
    },
    "FILTERBAR": {
      "ALL_FILTERS": "Alle filtre",
      "FILTER_BY_PRICE": "Filtrer efter pris",
      "FILTER_BY_POINTS": "Filtrer efter %pointsLabel",
      "PRICE_RANGE": "%min - %max %pointsLabel",
      "SORT": "Sorter",
      "TOTAL_COUNT": "Viser %total produkt(er)",
      "SORT_NAME_DOWN": "Navn: Ø-A",
      "SORT_NAME_UP": "Navn: A-Ø",
      "SORT_PRICE_DOWN": "Pris: Høj",
      "SORT_PRICE_UP": "Pris: Lav",
      "SORT_POSITION_DOWN": "Mest populær",
      "SORT_POPULAR_DOWN": "Mest populær",
      "SORT_VALUE_DOWN": "Værdi: Lav",
      "SORT_VALUE_UP": "Værdi: Høj",
      "SORT_RELEASE_DATE_DOWN": "Sidst tilføjet",
      "SORT_LATEST_DOWN": "Sidst tilføjet",
      "SORT_RELEVANCE_DOWN": "Relevans",
    },
  },
  "TRANSACTIONS": {
    "TRANSACTION_ID": "Transaktions-ID",
    "CLUB_OWNER": "Klubejer",
    "PRODUCT_ID": "Produkt-ID",
    "QTY": "Antal",
    "COST": "Pris",
    "MEMBER_ID": "Medlems-ID",
    "MEMBER_NAME": "Medlemsnavn",
    "DATE": "Dato",
    "COMPANY": "Firma",
    "EMAIL": "Email",
    "ADDRESS": "Adresse",
    "POSTCODE": "Postnummer",
    "CITY": "By",
    "COUNTRY": "Land",
    "TELEPHONE": "Telefon",
    "ALL_TRANSACTIONS": "Alle transaktioner",
    "ONLY_INCOMING": "Kun indgående transaktioner",
    "ONLY_OUTGOING": "Kun udgående transaktioner",
    "SEARCH": "Søg efter transaktion",
    "ID": "ID",
    "TRANSACTION": "Transaktion",
    "POINTS": "%pointsLabel",
    "RECEIPT": "Kvittering",
    "RECEIPT_DETAILS": "Se kvittering",
    "POINTS_EXPIRES": "%pointsLabel går ud",
    "REFUND": "Tilbagebetale",
  },
  "REPORTS": {
    "TITLE": "Rapporter",
    "HISTORY_BUTTON": "Historie",
    "BACK": "Tilbage",
    "EMPTY": "Der er ingen rapporter at vise",
    "ERROR": "Kunne ikke indlæse rapporter.",
    "HISTORIE": {
      "TITLE": "Historie",
      "SUBTITLE": "Her ser du alle indsendte rapporter.",
      "TRANSACTIONS": "Transaktionshistorik",
    },
    "APPROVAL": {
      "TITLE": "Klar markering",
      "SUBTITLE_PART1": "Her sletter du ordrerne",
      "SUBTITLE_PART2": "ryd mærke",
      "SUBMIT": "Udført",
    },
    "DOCUMENT": "Dokument",
  },
  "LEADERBOARD": {
    "TITLE": "Leaderboard",
    "SUBTITLE_PART1": "Her kan du se din placering i henhold til vores konferencerejse. Held og lykke",
    "SUBTITLE_PART2": "Din placering og oversigt",
    "POSITION_OF_CONTESTANTS": "af %amount %contestants",
    "MISSING_USER_DATA": "Din bruger mangler point for den valgte periode.",
    "MISSING_DATA": "Ingen data tilgængelig for den valgte periode.",

    "HELT": {
      "SHOW": "Vis",
      "MONTH": "Måned",
      "YEAR": "År",
    },
  },
  "WISHLIST": {
    "TITLE": "Ønskeliste",
    "BACK": "Gå til min konto",
  },
  "NEWS": {
    "TITLE": "Nyheder",
    "LATEST": "De seneste nyheder",
    "SHOW_ALL": "Vis alle nyheder",
    "NO_NEWS": "Beklager, der er ingen nyheder at læse lige nu.",
  },
  "OFFERS": {
    "LINK": "Gå til tilbud",
    "EMPTY": "Der findes ingen tilbud i øjeblikket",
  },
  "GALLERY": {
    "CLOSE": "Luk billedvisning",
  },
  "ADDRESS": {
    "FIRSTNAME": "Fornavn",
    "LASTNAME": "Efternavn",
    "STREET_1": "Gadeadresse 1",
    "STREET_2": "Gadeadresse 2 (valgfri)",
    "POSTCODE": "Postnummer",
    "CITY": "By",
    "COUNTRY": "Land",
    "EMAIL": "Email",
    "TELEPHONE": "Telefon",
    "COMPANY": "Firma",
  },
  "VALUE": "Værdi",
  "CART": {
    "TITLE": "Indkøbskurv",
    "EMPTY": "Indkøbskurv er tom",
    "START_SHOPPING": "Start shopping",
    "CTA": "Gå til kassen",
    "ALT": "Ændre i indkøbskurven",
    "EDIT": "Ændre i indkøbskurven",
    "TO_PAY": "At betale",
    "INCREMENT": "Forøg mængden",
    "DECREMENT": "Reducer mængden",
    "QUANTITY": "Mængde",
    "OPEN_CART": "Åbn indkøbskurv",
    "CLOSE": "Luk",
    "CLOSE_CART": "Luk kassen",
    "CONTINUE_SHOPPING": "Forsæt med at handle",
    "PRODUCTS": "Produkter",
    "SHIPPING": "Fragt",
    "SUBTOTAL": "Beløb",
    "TOTAL": "Totalt",
    "GRANDTOTAL": "I alt",
    "YOUR_BALANCE": "Din %pointsLabel-balance",
    "SUMMARY": "Resume",
    "TO_CHECKOUT": "Gå til kassen",
    "MOVE_TO_WISHLIST": "Flyt til ønskeliste",
    "REMOVE_PRODUCT": "Slet produkt",
    "INSUFFICIENT_FUNDS_HEADER_POSITIVE": "Din ordreværdi er blevet godkendt.",
    "INSUFFICIENT_FUNDS_HEADER_NEGATIVE": "Din ordreværdi er for høj",
    "INSUFFICIENT_FUNDS_BODY": "For at gennemføre dit køb skal mindst %spendingLimit% af din ordre betales i %pointsLabel. Hvis din ordreværdi er højere end det, der er tilladt for at gennemføre købet, skal du fjerne produkter for at fortsætte.",
    "INSUFFICIENT_FUNDS_POINTS_ONLY_BODY": "Din kurvværdi er højere end din %pointsLabel-saldo. Et eller flere af dine produkter kan ikke betales i rater. Du skal fjerne et eller flere af dine produkter fra din indkøbskurv for at gennemføre købet.",
    "INSUFFICIENT_FUNDS_BOTTOM_LABEL": "Ordreværdien skal reduceres med",
    "INSUFFICIENT_FUNDS_SUMMARY": "Indkøbskurv overskredet med",
  },
  "CHECKOUT": {
    "TITLE": "Kasse",
    "NEED_HELP": "Har du brug for hjælp? Vi er her for dig!",
    "CONTACT_CUSTOMER_SERVICE": "Kontakt kundeservice",
    "TO_PAYMENT": "Gå til betaling",
    "CHOOSE_PAYMENT_METHOD": "Videre til betalning",
    "POINTS_TITLE": "Betal med %pointsLabel",
    "POINTS_BALANCE": "Du har %points. Træk for at vælge, hvor mange %pointsLabel du vil bruge. Du kan betale det resterende beløb (op til %currencyLimit% af ordreværdien) med kreditkort.",
    "POINTS_BALANCE_JAVA": "Du har %points. %javatext",
    "AMOUNT_TO_PAY": "At betale",
    "POINTS": "%pointsLabel",
    "PAY_WITH_CARD": "Betal med kort",
    "EDIT": "Ændre",
    "PAYMENT_AND_DELIVERY": "Betaling og levering",
    "DISTRIBUTION": "Fordeling",
    "SENT_TO": "Sendes til",
    "ADDRESSES_TITLE": "Leveringsadresse",
    "EDIT_ADDRESS": "Skift adresse",
    "ADDRESS_MINIMIZE": "Minimer",
    "CONFIRM_ORDER": "Gennemfør ordre",
    "CONTINUE_SHOPPING": "Begynd at handle",
    "PAYMENT_TITLE": "Betalning",
    "CARD_PAYMENT": "Kortbetalning",
    "I_ACCEPT_THE": "Jeg accepterer",
    "TERMS": "købsbetingelser",
    "A_TERMS": "Vilkår og betingelser",
    "EX_TAX": "Ekskl. Moms",
    "INC_TAX": "Inkl. Moms",
    "EX_TAX_FULL": "Eksklusive moms",
    "INC_TAX_FULL": "Inklusiv moms",
    "PAYMENT_DISTRIBUTION": "Opdel betaling",
    "AND": "og",
    "MODE_OF_DELIVERY": "Leveringsmetode",
    "VIRTUAL_DELIVERY": "Din ordre bliver levereret digitalt via e-mail.",
    "ADDRESS": {
      "ADDRESS": "Adresse",
      "SHIPPING": "Leveringsadresse",
      "BILLING": "Kvitteringsadresse",
      "SHIP_TO_SAME_ADDRESS": "Samme adresse på kvittering",
      "EMPTY_ADDRESS": "Adressen er ikke komplet. Opdater venligst",
      "CONTACT_INFO": "Kontaktoplysninger",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Indkøbskurv",
        "LONG": "Indkøbskurv",
      },
      "SHIPPING": {
        "SHORT": "Levering",
        "LONG": "Kontroller din adresse",
      },
      "PAYMENT": {
        "SHORT": "Fordel betaling",
        "LONG": "Fordel din betaling",
      },
      "OVERVIEW": {
        "SHORT": "Betale",
        "LONG": "Oversigt",
      },
    },
  },
  "PAYMENT": {
    "TITLE": "Hvordan vil du betale?",
    "USE_PAY": "Betal nu",
    "USE_CARD": "Betal med kort",
    "USE_SAVED_CARD": "Betal med gemt kort",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "SUCCESS": {
    "TITLE": "Ordre registreret",
    "SUB_TITLE": "Ordrenummer %number",
    "QTY": "Antal: %qty",
    "STEPS": {
      "TITLE": "Tak for din bestilling!",
      "HEADING": "Ordre registreret",
      "WHAT_NOW": "Så hvad sker der nu?",
      "LABEL": "Din ordre",
      "ORDER_ID": "Ordrenummer %id",
      "QUESTIONS": {
        "HEADING": "Har du et spørgsmål om din ordre?",
        "LABEL": "Gå til kundeservice",
        "URL": "/faq",
      },
      "CONFIRMATION": {
        "1": {
          "HEADING": "Bekræftelse",
          "TEXT": "Tak for din bestilling! En ordrebekræftelse vil blive sendt til den angivne e-mailadresse: %email snart. Hvis du ikke modtager e-mailen, bedes du kontrollere, om den er endt i din spam-mappe.",
        },
        "2": {
          "HEADING": "Levering",
          "TEXT": "Leveringen af din ordre vil foregå fra vores eget lager eller fra en af vores samarbejdspartnere. Dette betyder, at ordren kan blive leveret i flere forsendelser. For leverancer fra vores eget lager sendes ordren, når alle produkter er på lager. Ordre for produkter fra vores eget lager leveres normalt inden for 3-7 arbejdsdage. Ved leverancer fra en af vores samarbejdspartnere sendes ordren direkte fra deres lager. Ordre for produkter fra vores samarbejdspartnere leveres normalt inden for 1-2 uger.<br/><br/>I ordrebekræftelsen, som vil blive sendt til din e-mailadresse snart, kan du se, hvordan din ordre vil blive leveret.<br/><br/>Digitale produkter sendes direkte til den e-mailadresse, du angav i bestillingen.",
        },
      },
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hej! Du bruger en forældet browser. <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">Opdater din browser</a> for at bruge siden.",
  },
  "NO_JAVASCRIPT": {
    "LOGIN": "Du skal aktivere Javascript for at logge ind.",
  },
  "CATEGORY": {
    "TOTAL_COUNT": "Viser %total produkt(er)",
    "EMPTY": "Der findes ingen produkter, at vise lige nu",
    "LOAD_MORE": "Indlæs mere",
    "PRODUCTS_IN_CATEGORY_TITLE": "Viser %itemsCount produkt i",
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viser 1 produkt i",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viser %itemsCount produkter i",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Viser produkter i",
  },
  "404": {
    "TITLE": "Beklager, siden kunne ikke findes.",
    "BACK": "Vil du gå tilbage til",
    "BACK_LINK": "hjem",
  },
  "SERVER_ERROR": {
    "TITLE": "Serverfejl",
    "TEXT": "<strong>Skal du kontakte os?</strong><br/>Send en e -mail til <a href=\"mailto:info@awardit.com\">info@awardit.com</a> ",
  },
  "ADMINISTRATION": {
    "APPROVE_MEMBERS": "Godkend medlemmer",
    "TITLE": "Administration",
  },
  "SEARCH": {
    "AND_ACTIVE_FILTERS": "og aktive filtre",
    "TITLE_PREFIX": "Søg: ",
    "PLACEHOLDER": "Hvad søger du efter?",
    "ERROR_MINIMUM_CHARACTERS": "Indtast mindst %characters tegn",
    "EMPTY_SEARCH_RESULT": "Ingen match fundet",
    "PRODUCT_IN_SEARCH_RESULT": "Fundet 1 produkt som matcher",
    "PRODUCTS_IN_SEARCH_RESULT": "Fundet %itemsCount matchende produkter",
    "SEARCHING_FOR": "Søger efter",
    "TYPE": {
      "CATEGORY": "Kategori",
      "BRAND": "Mærke",
    },
  },
  "HEADER": {
    "CLOSE_MENU": "Luk menu",
    "SEARCH": "Søg",
    "CATEGORIES": "Kategorier",
    "CLOSE_CATEGORIES": "Luk kategorier",
    "AFFILIATE_CATEGORIES": "Partnere",
    "BRANDS": "Varemærker",
    "EARN": "Optjen %pointsLabel",
    "REDEEM": "Brug dine %pointsLabel",
    "CLOSE_BRANDS": "Luk varemærker",
    "WISHLIST": "Ønskeliste",
    "ACCOUNT": "Min konto",
    "CHECKOUT": "Kassen",
    "LOGOUT": "Log ud",
    "DELIVER_TO": "Leveret til",
    "HOW_DOES_IT_WORK": "Hvordan fungerer det?",
    "MY_ACCOUNT": "Min konto",
    "CUSTOMER_SERVICE": "Kundeservice",
    "OUR_OFFER": "Vores tilbud",
    "NEWS": "Nyheder",
    "CATEGORY_SEE_ALL": "Se alt i %categoryName",
    "QUESTIONNAIRES": "Undersøgelser",
    "OFFER_LIST": "Tilbud",
    "FAQ": "Ofte stillede spørgsmål",
  },
  "FOOTER": {
    "CATEGORIES": "Kategorier",
    "LINKS": {
      "TITLE": "Links",
      "START": "Hjem",
      "CATEGORIES": "Kategorier",
      "BRANDS": "Varemærker",
      "SEARCH": "Søg i sortimentet",
      "WISHLIST": "Ønskeliste",
      "MY_ACCOUNT": "Min konto",
      "GO_TO_MY_ACCOUNT": "Gå til min konto",
      "ALL_TRANSACTIONS": "Se alle transaktioner",
    },
    "NEED_HELP": {
      "TITLE": "Har du brug for hjælp?",
      "CONTACT": "Kontakt os",
      "DATE": "Hverdage 10-12",
      "COOKIES": "Administrer cookies",
    },
    "LEGAL": {
      "PRIVACY_POLICY": "Integritetspolicy",
      "TERMS": "Købsbetingelser",
      "FAQ": "Ofte stillede spørgsmål",
      "PRIVACY_NOTICE": "Privacy Notice",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Hjem",
    "SEARCH": "Søgeresultater for \"%query\"",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Tilføj til kurv",
    "OUT_OF_STOCK": "Slut i lager",
    "DESCRIPTION": "Produktbeskrivning",
    "OTHERS_ALSO_LIKED": "Andre kunne godt lide",
    "OPTION_DROPDOWN_PLACEHOLDER": "Vælg et valg",
    "LOGIN_TO_PURCHASE": "Log ind for at kunne handle",
    "CHANGE_IMAGE": "Skift billede",
    "INSUFFICIENT_FUNDS_HEADING": "Delbetalning",
    "INSUFFICIENT_FUNDS_BODY": "Hvis du ikke har nok %pointsLabel til at købe et produkt, kan du delbetale med penge i kassen. Husk, at mindst %currencyLimit% af ordreværdien skal være %pointsLabel.",
    "PERQUISITE_VALUE": "%value (købsværdi ekskl. moms til beregning af beskatning)",
    "RESTRICTION_PRODUCT_ADD_TO_CART": "Denne vare kan kun købes uden andre varer i indkøbskurven. Ønsker du at fjerne de nuværende varer fra kurven og fortsætte?",
    "RESTRICTION_PRODUCT_IN_CART": "Produktet, du tilføjer til kurven, kan ikke kombineres med de eksisterende produkter. Vil du fjerne varerne i kurven og tilføje denne?",
    "PRODUCTS_CANNOT_BE_COMBINED": "Produkter kan ikke kombineres",
    "SPECIFIC_POINT_DEFICIT_HEADING": "Du har ikke tilstrækkeligt med point til at købe denne vare",
    "INSUFFICIENT_FUNDS_SPECIFIC_PARTNER": "Produkterne i din indkøbskurv kan kun købes med point optjent hos %partnerName.",
    "SPECIFIC_POINT_DEFICIT_BODY": "Denne vare kan kun købes med point optjent hos %partner, og du mangler i øjeblikket %points.",
    "ESTIMATED_DELIVERY": {
      "NORMAL": "Levering inden for 3-7 arbejdsdage",
      "VIRTUAL": "Leveres direkte med e-mail",
    },
  },
  "STRIPE": {
    "CARD_NUMBER": "Kortnummer",
    "MM/YY": "MM/ÅÅ",
    "CVC": "CVC",
  },
  "VALIDATION": {
    "REQUIRED": "Dette felt er påkrævet",
    "EMAIL": "Angiv en gyldig e-mail-adresse",
    "PHONE": "Angiv et gyldigt mobiltelefonnummer",
    "NUMERIC": "Feltet må kun indeholde tal",
    "LENGTH_LT": "Feltet indeholder for mange tegn",
    "POSTCODE": "Indtast venligst det korrekte postnummer (XXXX)",
    "MATCH": "Adgangskoden matcher ikke",
    "NUMBER_GT": "Værdien skal være større",
    "NUMBER_LT": "Værdien skal være mindre",
    "DATE_INVALID_FORMAT": "Forkert format (ÅÅÅÅ-MM-DD)",
    "DATE_INVALID_DATE": "Ugyldig dato",
    "NOT_A_VALID_GENDER": "Vælg et køn",
    "PASSWORD_VALIDATE": "Mindst 6 tegn",
  },
  "MESSAGE": {
    "ERROR": "Fejl",
    "SUCCESS": "Succes",
    "INSUFFICIENT_FUNDS": {
      "HEADING": "Værdien for din kurv er for høj",
      "BODY": "Din indkøbskurvs værdi er mere end %spendingLimit% af din %pointsLabel-balance, du skal fjerne et eller flere produkter i din indkøbskurv for at fuldføre købet.",
      "POINTS_ONLY_BODY": "Din kurvværdi er højere end din %pointsLabel-saldo. Et eller flere af dine produkter kan ikke betales i rater. Du skal fjerne et eller flere af dine produkter fra din indkøbskurv for at gennemføre købet.",
      "BUTTON_LEFT": "Behandle senere",
      "BUTTON_RIGHT": "Åbn indkøbskurv",
    },
    "MISSING_DETAILS": {
      "HEADING": "Handling påkrævet",
      "NUMBER_NOT_FOUND": "Din profil har ikke et telefonnummer. Udfyld venligst dine detaljer.",
      "DOB_NOT_FOUND": "Din profil har ikke en fødselsdato. Udfyld venligst dine detaljer.",
      "FIRSTNAME_NOT_FOUND": "Din profil har ikke et fornavn. Udfyld venligst dine detaljer.",
      "LASTNAME_NOT_FOUND": "Din profil har ikke et efternavn. Udfyld venligst dine detaljer.",
      "EMAIL_NOT_FOUND": "Din profil har ikke en e-mail. Udfyld venligst dine detaljer.",
      "GENDER_NOT_FOUND": "Din profil har ikke en køn. Udfyld venligst dine detaljer.",
      "ORGNR_NOT_FOUND": "Din profil har ikke et organisationsnummer. Udfyld venligst dine detaljer.",
      "BUTTON_LEFT": "Administrer senere",
      "BUTTON_RIGHT": "Åbn min konto",
    },
    "BLOCKED": "Du kan i øjeblikket ikke handle i butikken, ved spørgsmål kontakt din kontaktperson.",
  },
  "STATUS_CODE": {
    /* Client */
    "FALLBACK": "Der opstod en fejl. Prøv igen.",
    "UNKNOWN": "Der opstod en ukendt fejl. Genindlæs siden, og prøv igen.",
    "NETWORK_ERROR": "Kopplingen afbrudt. Kontroller, at du har en aktiv internetforbindelse, eller prøv igen om et øjeblik.",
    "PLACE_ORDER_CATCH": "Der opstod en fejl. Prøv igen senere.",
    "CHANGE_PASSWORD_ERROR": "Der opstod en fejl. Prøv igen senere.",
    "CHANGE_PASSWORD_SUCCESS": "Din adgangskode er nu blevet opdateret.",
    "MAIL_SENT": "Der er sendt en e-mail til dig.",
    "PASSWORD_UPDATE": "Adgangskode opdateret.",
    "TRANSFER_POINTS_ERROR": "Pverførsel mislykkedes.",
    "TRANSFER_POINTS_SUCCESS": "Overførsel lykkedes.",
    "SEND_ORDER_SUCCESS": "Din rapport er blevet sendt.",
    "SEND_ORDER_ERROR": "Der opstod en fejl. Prøv venligst igen senere.",

    "REMOVE_MEMBER_ERROR": "Der opstod en fejl under fjernelse af medlemmet.",
    "REMOVE_MEMBER_SUCCESS": "Medlemmet er fjernet.",
    "ADD_MEMBER_ERROR": "Der opstod en fejl under tilføjelse af medlemmet.",
    "ADD_MEMBER_SUCCESS": "Medlemmet er tilføjet.",
    "UPDATE_MEMBER_ERROR": "Der opstod en fejl under opdatering af medlemmet.",
    "UPDATE_MEMBER_SUCCESS": "Medlemmet er opdateret.",
    "GET_TRANSFERABLEMEMBERS_ERROR": "Kunne ikke hente medlemmer. Prøv venligst igen.",
    "TRANSFER_MEMBER_POINTS_ERROR": "Kunne ikke overføre point, prøv venligst igen.",
    "TRANSFER_MEMBER_POINTS_SUCCESS": "Pointene er blevet overført.",

    "errorProductQuantityNotAvailable": "Den indtastede mængde er i øjeblikket ikke tilgængelig.",
    "errorProductNotInStock": "Beklager, det produkt, du forsøgte at tilføje til kurven, er i øjeblikket ikke på lager.",
    "errorProductMaxQuantity": "Den angivne mængde er i øjeblikket udsolgt.",
    "errorProductNotChosenVariant": "Vælg %attributeLabel for at tilføje produktet til din indkøbskurv.",
    "termsNotAccepted": "Du skal acceptere købsbetingelserne for at fuldføre dit køb.",

    "errorInvalidLogin": "Forkerte loginoplysninger.",
    "errorLoginFailed": "Ugyldigt brugernavn eller adgangskode. Prøv igen, eller nulstil din adgangskode.",
    "errorMissingCustomer": "Kunden du prøver at logge ind på, findes ikke.",
    "errorInsufficientawarditPointsForPurchase": "Saldo på konto er for lavt.",
    "errorBlacklistedEmail": "Den indtastede e-mailadresse er allerede i brug. Angiv en anden.",
    "errorInvalidEmailOrPassword": "Forkerte loginoplysninger.",
    "errorResetPassword": "Adgangskoden kunne ikke nulstilles.",
    "updateCustomerEmail_errorNotLoggedIn": "Du ser ikke ud til at være logget ind. Genindlæs siden, og prøv igen.",
    "updateCustomerEmail_errorInvalidEmail": "E -mailadressen er ikke gyldig. Kontroller og prøv igen.",
    "updateCustomerEmail_errorEmailExists": "Den indtastede e -mailadresse er allerede i brug. Angiv en anden.",
    "QUIZ_ERROR_ANSWERED": "Du har allerede besvaret denne quiz",
    "SEND_QUIZ_SUCCESS": "Dit svar er sendt",
    "TEAMPOT_ERROR": "Der opstod en fejl, %pointsLabel blev ikke sendt",
    "TEAMPOT_SUCCESS": "Punktene er blevet sendt",
    "TEAMPOT_OPTION_ERROR": "Aktivitetslinjen må ikke være tom.",
    "TEAMPOT_POINTS_ERROR": "Punktfeltet må ikke være tomt.",
    "TEAMPOT_RANK_ERROR": "Rangeringsfeltet må ikke være tomt.",
    "TEAMPOT_USERS": "Medlemmer",

    "CART_NO_ITEMS": "Din kurv er tom.",

    "AFFILIATE": {
      "ACTIVATED": "Tilbuddet er aktiveret.",
      "NOT_ACTIVATED": "Tilbuddet kunne ikke aktiveres. Prøv igen.",
    },

    "STRIPE_ERROR": {
      "YOUR_CARDS_EXPIRATION_DATE_IS_INCOMPLETE": "Dit korts udløbsdato er ufuldstændig",
    },

    "TINK_CALLBACK": {
      "1": "Du har nu registreret dit samtykke og kan nu få %pointsLabel på dine butikskøb.", // Complete
      "2": "Vi kunne ikke registrere dit samtykke. Kontakt support.",
      "3": "Vi kunne ikke hente oplysninger fra Tink. Prøv igen senere.",
      "4": "Tink returnerede en fejl. Prøv igen senere.",
      "5": "Noget gik galt under forbindelse til Tink. Prøv igen senere, eller kontakt support.",
      "6": "Der er noget galt med linket til Tink. Kontakt support.",
      "7": "Du har annulleret autentificeringen.",
      "8": "Autentificeringen mislykkedes.",
      "9": "En intern fejl hos Tink. Kontakt support.",
      "10": "En midlertidig fejl hos Tink. Prøv igen senere, og kontakt support, hvis fejlen fortsætter.",
      "UNKNOWN": "Noget gik galt under forbindelse til Tink. Prøv igen senere, eller kontakt support.",
      "GENERIC_ERROR": "Der opstod en fejl. Prøv igen.",
    },

    "REGISTER_MEMBER": {
      "SUCCESS_ACTIVATE": "Din konto er nu aktiveret!",
      "SUCCESS_REG": "Dine brugeroplysninger er nu registreret. Venligst tjek din e-mail for at aktivere din konto.",
    },

    "RECOMMEND_MEMBER": {
      "ERROR": "Kunne ikke sende anbefalinger til brugeren.",
    },

    "ITEM_REMOVED_FROM_CART": "%name blev fjernet fra indkøbskurven, fordi den ikke længere er på lager.",

    "MEMBERGROUP": {
      "MEMBER_UPDATED": "Gruppemedlemmet er nu opdateret.",
      "MEMBER_ADDED": "Gruppemedlem er nu føjet til gruppen.",
      "MEMBER_REMOVED": "Gruppemedlem er nu fjernet fra gruppen.",
    },
  },

  "ADDITIONAL_MEMBER_NUMBERS": {
    "TABLE": {
      "NAME": "Navn",
      "NUMBER": "Kundenummer",
    },
  },

  "MOTIVATION_POT": {
    "MOTIVATION_POT_TITLE": "Holdpot",
    "SUB_TITLE": "Her kan du uddele %pointsLabel til medarbejderne",
    "USERS_HEADER": "Fordeling af %pointsLabel",
    "CHOOSE_ALL": "Vælg alle",
    "POINTS": "%pointsLabel",
    "POINTS_OF_CHOSEN": "%pointsLabel (x %userAmount valgt)",
    "POINTS_BALANCE_ERROR": "Din %pointsLabel-saldo er desværre ikke nok",
    "COMMENT": "Kommentar",
    "POINTS_AVAILABLE": "%pointsLabel til rådighed",
    "POINTS_HANDED_OUT": "Tildelt %pointsLabel",
    "POINTS_LEFT_IN_POT": "%pointsLabel tilbage i puljen",
    "POINTS_HEADER": "%pointsLabel-pot",
    "PERIOD": "Periode",
    "TOTAL_POINTS": "Samlet %pointsLabel-pot",
    "POINTS_LEFT": "%pointsLabel tilbage",
    "PREVIOUS_HANDED_OUT_HEADER": "Tidligere udbytte",
    "ACTIVITY": "Aktivitet",
    "MEMBER_COUNT": "Antal medlemmar",
    "RANK": "Rang",
    "RANK_OF_CHOSEN": "Rang (x %userAmount valgt)",
    "DATE": "Dato",
    "RECEIVERS": "Modtager",
  },
  "OR": "Eller",
  "MEMBERGROUPVIEW": {
    "PARTICIPANT": "Deltager",
    "STATUS": "Status",
    "GROUP_MEMBER": "Gruppemedlem",
    "POINTS": "%pointsLabel",
    "TRANSACTIONS": "Se transaktioner",
    "REMOVE": "Fjerne",
    "UPDATE": "Opdatering",
    "POINTSOPTION_0": "Medlemmet kan kun se og indløse sine egne %pointsLabel",
    "POINTSOPTION_1": "Medlemmet kan kun se gruppens %pointsLabel, men kan indløse deres egne %pointsLabel, hvis de har nogle",
    "POINTSOPTION_2": "Medlemmet kan se og indløse alle %pointsLabel",
    "YES": "Ja",
    "NO": "Nej",
    "REMOVE_TITLE": "Fjern gruppemedlem",
    "REMOVE_CONFIRM": "Vil du virkelig fjerne gruppemedlem \"%member\"?",
    "ADD_MEMBER": "Tilføj nyt medlem",
    "ORGNR": "Organisationsnummer",
    "LINK": "Administrer medlemsgruppe",
  },
};
